<template>
  <div>
    <v-list color="primary-light">
      <v-list-item-group color="primary" :value="highlight">
        <v-list-item
          v-for="member in members"
          :key="member.email"
          :color="member.email === loggedInUser ? 'purple' : 'primary'"
        >
          <v-list-item-icon>
            <MemberContactCard :member="member" />
          </v-list-item-icon>
          <MemberDetail @click="$emit('click', member)" :editable="editable" :member="member" />
          <div class="chip-container">
            <v-chip
              :x-small="$vuetify.breakpoint.xs"
              :small="!$vuetify.breakpoint.xs"
              v-for="perm in getPermissions(member)"
              :key="perm"
              :color="permColor(perm)"
              class="mr-1 chip text-capitalize"
              dark
            >
              {{ label(perm) }}
            </v-chip>
            <v-chip
              color="indigo"
              dark
              class="chip text-capitalize"
              :x-small="$vuetify.breakpoint.xs"
              :small="!$vuetify.breakpoint.xs"
              v-if="member.team"
              >{{ member.team }}</v-chip
            >
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import _ from 'lodash';
import { PERMISSIONS } from '../../utils/organization';
export default {
  filters: {
    fmtCamelCase(str) {
      return _.camelCase(str);
    }
  },
  components: {
    MemberDetail: () => import('./MemberDetail.vue'),
    MemberContactCard: () => import('./ContactCard.vue')
  },
  props: {
    members: {
      type: Array,
      default: null
    },
    highlight: {
      type: Number,
      default: -1
    },
    loggedInUser: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    label(perm) {
      return PERMISSIONS[perm].text;
    },
    permColor(perm) {
      return PERMISSIONS[perm].color;
    },
    getPermissions(member) {
      const permissions = [];
      const memberPermission = member.permissions || {};
      Object.keys(PERMISSIONS).forEach(p => {
        if (memberPermission[p] && this.data[p].includes(member.email)) {
          permissions.push(p);
        }
      });
      return permissions;
    }
  }
};
</script>

<style>
</style>
